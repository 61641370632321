import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  wrapper: {
    display: 'grid',
    position: 'absolute',
    width: '80%',
    left: '16.5%',
  },
  logs: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2vh',
    height: '70vh',
    maxHeight: '90vh',
    padding: '1vh',
    overflow: 'auto',
  },
  actionButtons: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '10px',
    marginTop: '2vh !important',
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
});

export default useStyles;
