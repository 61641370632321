import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

interface SettingsProps {
  chargePointId: string;
  isDisabled?: boolean;
}

export const Settings: React.FC<SettingsProps> = ({ chargePointId, isDisabled }) => {
  const navigate = useNavigate();

  return (
    <Tooltip title="Settings">
      <IconButton
        onClick={() => navigate(`/charger/${chargePointId}/settings`)}
        disabled={isDisabled}
      >
        <SettingsIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
};

Settings.defaultProps = {
  isDisabled: false,
};
