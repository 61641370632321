export * from './chargePointStatuses';
export * from './connector';

export interface ChildrenInterface {
  children: React.ReactNode;
}
export interface MessagePayloadInterface {
  status: string;
  connectorId: number;
  reason?: string;
  interval?: number;
  data?: string;
  meterValue?: number;
  selectedConnectorId?: number;
  connectors?: ConnectorPayload[];
}

export interface ConnectorPayload {
  connectorId: number;
  meterValue: number;
  transactionStartMeterValue: number;
  hasOpenTransaction: boolean;
  isPlugged: boolean;
  transactionId: number;
  connectorStatus: string;
}

export interface MessageInterface {
  messageType: string;
  payload: MessagePayloadInterface;
}

export const messageTypes = [
  'Connect',
  'Heartbeat',
  'BootNotification',
  'ResetClientConnection',
  'StartTransaction',
  'StopTransaction',
  'StartMeterValues',
  'StopMeterValues',
  'UpdateSettings',
  'StartCharging',
  'StopCharging',
  'GetSettings',
  'GetCsmsConnectionStatus',
  'Reset',
  'Plug',
  'Unplug',
  'GetLogs',
  'SelectConnector',
  'GetCPInformation',
  'GetStatus',
];

export const logsMessageTypes = [
  'Connect',
  'Heartbeat',
  'BootNotification',
  'StartTransaction',
  'StopTransaction',
  'StatusNotification',
  'Authorize',
  'MeterValues',
  'RemoteStopTransaction',
  'RemoteStartTransaction',
  'GetConfiguration',
  'Reset',
  'ChangeConfiguration',
  'UnlockConnector',
  'RequestStartTransaction',
  'RequestStopTransaction',
  'TransactionEvent',
];

export type MessageType = typeof messageTypes[number];

export enum MessageTypeEnum {
  CONNECT = 'Connect',
  RESET_CLIENT_CONNECTION = 'ResetClientConnection',
  START_TRANSACTION = 'StartTransaction',
  STOP_TRANSACTION = 'StopTransaction',
  START_METER_VALUES = 'StartMeterValues',
  STOP_METER_VALUES = 'StopMeterValues',
  UPDATE_SETTINGS = 'UpdateSettings',
  START_CHARGING = 'StartCharging',
  STOP_CHARGING = 'StopCharging',
  GET_SETTINGS = 'GetSettings',
  GET_CSMS_STATUS = 'GetCsmsConnectionStatus',
  PLUG = 'Plug',
  UNPLUG = 'Unplug',
  GET_LOGS = 'GetLogs',
  SELECT_CONNECTOR = 'SelectConnector',
  GET_CP_INFO = 'GetCPInformation',
  GET_STATUS = 'GetStatus',
}
