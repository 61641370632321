import { Components, Theme } from '@mui/material';
import { MuiCard } from './card';
import { MuiTypography } from './typography';
import { MuiTextField } from './textField';
import { MuiContainer } from './container';
import { MuiToolbar } from './toolbar';
import { MuiIconButton } from './iconButton';

export const components: Components<Omit<Theme, 'components'>> = {
  MuiCard,
  MuiTypography,
  MuiTextField,
  MuiContainer,
  MuiToolbar,
  MuiIconButton,
};
