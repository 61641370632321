import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Autocomplete,
  Button,
  Checkbox,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import { useLogs } from 'store/logs';
import { useClearLogs } from 'store/clearLogs';
import { LogsModal } from '../../components/modal/LogsModal/LogsModal';
import { logsMessageTypes } from '../../types';
import useStyles from './Logs.styles';

export const Logs: React.FC = () => {
  const { logs } = useLogs();
  const [filter, setFilter] = React.useState<string[]>([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [lastOpenedModal, setLastOpenedModal] = React.useState('');
  const { clearLogsDate, setClearLogsDate } = useClearLogs();

  const chargePointId = useParams().id as string;
  const navigate = useNavigate();
  const classes = useStyles();

  const filteredLogs = React.useMemo(() => {
    if (!isOpen) {
      return logs.filter((log) => {
        const logTimestamp = new Date(log.split(' | ')[0]);

        const matchesFilter = filter.length === 0 || filter.includes(log.split(' | ')[2]);
        const matchesClearDate = !clearLogsDate || logTimestamp > clearLogsDate;

        return matchesFilter && matchesClearDate;
      });
    }
    return logs;
  }, [isOpen, logs, filter, clearLogsDate]);

  const checkIfModalIsOpened = (key: string, open: boolean) => {
    if (open) {
      setLastOpenedModal(key);
      setIsOpen(open);
    }

    if (key === lastOpenedModal && !open) {
      setIsOpen(false);
      setLastOpenedModal('');
    }
  };

  const handleFilterChange = React.useCallback(
    (value: string[]) => {
      if (value.includes('Select all')) {
        setFilter(filter.length === logsMessageTypes.length ? [] : [...logsMessageTypes]);
      } else {
        setFilter(value);
      }
    },
    [filter.length],
  );

  return (
    <div className={classes.wrapper}>
      <Typography
        variant="h2"
        textAlign="center"
        fontWeight="bold"
      >
        Logs for: {chargePointId}
      </Typography>
      <div className={classes.actionsWrapper}>
        <Autocomplete
          multiple
          id="filter-message-types"
          options={['Select all', ...logsMessageTypes]}
          disableCloseOnSelect
          onChange={(_, value) => {
            handleFilterChange(value);
          }}
          limitTags={5}
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              key={option}
            >
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={option !== 'Select all' ? selected : filter.length === logsMessageTypes.length}
              />
              {option}
            </li>
          )}
          style={{ width: '50%' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filter by message types"
              placeholder="Select message types"
            />
          )}
          value={filter}
        />

        <div className={classes.actionButtons}>
          <Button
            variant="contained"
            size="large"
            startIcon={<DeleteIcon />}
            onClick={() => setClearLogsDate(new Date())}
          >
            Clear logs
          </Button>

          <Button
            variant="contained"
            size="large"
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIcon />}
          >
            Go back
          </Button>
        </div>
      </div>

      <Paper
        elevation={5}
        className={classes.logs}
      >
        <Typography fontFamily="monospace">
          {filteredLogs.map((log, index) => {
            const [timestamp, callType, messageType, messageId, payload] = log.split(' | ');

            let resultPayload;
            let callPayload;

            if (callType === 'CALL') {
              resultPayload = logs
                .find(
                  (log) => log.includes(messageId) && log.includes('CALL RESULT'),
                )
                ?.split(' | ')[4];

              callPayload = payload;
            } else {
              callPayload = logs
                .find((log) => log.includes(messageId) && !log.includes('CALL RESULT'))
                ?.split(' | ')[4];

              resultPayload = payload;
            }

            return (
              <LogsModal
                key={`${index}-${messageId}`}
                messageType={messageType}
                messageId={messageId}
                timestamp={timestamp}
                callPayload={callPayload}
                resultPayload={resultPayload}
                log={log}
                isOpenCallBack={isOpen => { checkIfModalIsOpened(`${index}-${messageId}`, isOpen); }}
              />
            );
          })}
        </Typography>
      </Paper>
    </div>
  );
};
