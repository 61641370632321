import { makeStyles } from '@mui/styles';
import { foundations } from '../../theming/foundations';

const useStyles = makeStyles({
  responsesContainer: {
    backgroundColor: '#f1f1f154',
    borderRadius: '5px',
    height: '32vh',
    maxHeight: '32vh',
    overflowY: 'auto',
    padding: '10px',
  },
  image: {
    width: '250px !important',
    '@media (min-height:900px)': {
      width: '300px !important',
    },
  },
  chargerPortIcon: {
    width: '150px',
    '@media (min-height:900px)': {
      width: '200px',
    },
    '&:hover': {
      cursor: 'pointer',
      filter: 'opacity(0.5)',
    },
  },
  charger: {
    display: 'flex',
    flexDirection: 'column',
    border: '5px solid black',
    borderRadius: '70px 70px 0 0',
    height: '75vh',
    boxShadow:
      'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;',
  },
  chargerButtonsContainer: {
    padding: '2rem 2rem 0rem 2rem',
    marginTop: 'auto',
    justifyContent: 'center',
    alignItems: 'center',

    '& *': {
      height: '6vh',
      width: '50%',
    },
  },
  chargerDisplay: {
    display: 'grid',
    margin: '3rem 2rem 0rem 2rem',
    borderRadius: '5px',
    padding: '15px',
    overflow: 'auto',
    marginBottom: '15px',
    backgroundColor: '#5e5e5e',
    color: 'white',
    minHeight: '15vh',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
  },
  chargerFooter: {
    display: 'flex',
    marginTop: '1rem',
    padding: '5px',
    color: 'white',
    backgroundColor: foundations.palette.primary.main,

    '& > :nth-child(1)': {
      paddingLeft: '5px',
      display: 'flex',
      alignItems: 'center',
    },

    '& > :nth-child(2)': {
      color: '#e10000',
    },

    '& > :nth-child(3)': {
      marginLeft: 'auto',
    },
  },
  boltIcon: {
    margin: 'auto',
  },
  linearProgress: {
    width: '50%',
    margin: 'auto',
  },
});

export default useStyles;
