import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

import {
  Grid,
  Card,
  Typography,
  Button,
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';

import { TransactionModal } from 'components/modal/TransactionModal.tsx/TransactionModal';
import { Settings } from 'components/Settings/Settings';
import { Logs } from 'components/Logs/Logs';

import chargerImage from 'assets/charging-station.png';
import { Status } from 'components/Status/Status';
import { MessagePayloadInterface, MessageType } from 'types';

import { useWs } from 'store/ws';
import { useChargePoint } from 'store/chargePoint';
import { OnlineOffline } from 'components/OnlineOffline/OnlineOffline';
import { DisconnectModal } from 'components/modal/DisconnectModal.tsx/DisconnectModal';
import { messageTypes } from '../../constants';

import useStyles from './ChargePointDebugPage.styles';

export const ChargePointDebugPage: React.FC = () => {
  const chargePointId = useParams().id || '';
  const [RFIDTag, setRFIDTag] = React.useState('');

  const { sendWSMessage } = useWs();
  const { selectedConnector, isConnectionOnline, connectors, messages } = useChargePoint();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleSendMessageToWebSocket = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    name?: string,
    connectorId?: number,
  ) => {
    sendWSMessage((event ? event.currentTarget.name : name) as MessageType, {
      chargePointId,
      idTag: RFIDTag,
      connectorId: connectorId || selectedConnector,
    });
  };

  const mapMessagePayload = (messagePayload: MessagePayloadInterface) => {
    return _.replace(JSON.stringify(messagePayload), /[^a-zA-Z0-9:, ]/g, ' ')
      .split(',')
      .map((payload) => {
        return (
          <React.Fragment key={payload}>
            {' '}
            {payload} <br />
          </React.Fragment>
        );
      });
  };

  return (
    <>
      <Typography
        variant="h2"
        textAlign="center"
        fontWeight="bold"
      >
        Charge Point: {chargePointId}
      </Typography>
      <Grid
        container
        alignItems="center"
        spacing={{ xl: 3, lg: 2, md: 2, sm: 1 }}
        textAlign="center"
        marginTop={{ xl: 2, lg: 1, md: 0 }}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
        >
          <Stack
            spacing={{ xl: 1, lg: 1, xs: 1 }}
            direction="column"
          >
            <div className={classes.toolboxContainer}>
              <Status
                chargePointStatus={
                  connectors[selectedConnector].chargePointStatus
                }
              />
              <span>
                <OnlineOffline
                  isOnline={isConnectionOnline}
                  debug
                />
                <Settings chargePointId={chargePointId} />
                <Logs chargePointId={chargePointId} />
              </span>
            </div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Connector</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedConnector}
                name={messageTypes.SELECT_CONNECTOR}
                label="Connector"
                onChange={(event) => {
                  handleSendMessageToWebSocket(
                    undefined,
                    messageTypes.SELECT_CONNECTOR,
                    event.target.value as number,
                  );
                }}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
              </Select>
            </FormControl>
            <Button
              name={messageTypes.PLUG}
              variant="contained"
              size="large"
              onClick={handleSendMessageToWebSocket}
              disabled={connectors[selectedConnector].isPlugged}
            >
              Plug Cable
            </Button>
            <Button
              name={messageTypes.LOCK}
              variant="contained"
              size="large"
              onClick={handleSendMessageToWebSocket}
              disabled={connectors[selectedConnector].isLocked || !connectors[selectedConnector].isPlugged}
            >
              Lock Connector
            </Button>
            <TransactionModal
              name="Start Transaction"
              messageType={messageTypes.START_TRANSACTION}
              RFIDTag={RFIDTag}
              setRFIDTag={setRFIDTag}
              handleSubmit={handleSendMessageToWebSocket}
              disabled={
                connectors[selectedConnector].isTransactionStarted
                || !connectors[selectedConnector].isPlugged
                || connectors[selectedConnector].chargePointStatus === 'Finishing'
              }
            />
            <Button
              name={messageTypes.START_METER_VALUES}
              variant="contained"
              size="large"
              onClick={handleSendMessageToWebSocket}
              disabled={
                connectors[selectedConnector].isMeterValueStarted
                || !connectors[selectedConnector].isTransactionStarted
              }
            >
              Start Meter Values
            </Button>
            <Button
              name={messageTypes.STOP_METER_VALUES}
              variant="contained"
              size="large"
              onClick={handleSendMessageToWebSocket}
              disabled={!connectors[selectedConnector].isMeterValueStarted}
            >
              Stop Meter Values
            </Button>
            <TransactionModal
              name="Stop Transaction"
              messageType={messageTypes.STOP_TRANSACTION}
              RFIDTag={RFIDTag}
              setRFIDTag={setRFIDTag}
              handleSubmit={handleSendMessageToWebSocket}
              disabled={!connectors[selectedConnector].isTransactionStarted}
            />
            <Button
              name={messageTypes.UNLOCK}
              variant="contained"
              size="large"
              onClick={handleSendMessageToWebSocket}
              disabled={!connectors[selectedConnector].isLocked}
            >
              Unlock Connector
            </Button>
            <Button
              name={messageTypes.UNPLUG}
              variant="contained"
              size="large"
              onClick={handleSendMessageToWebSocket}
              disabled={
                !connectors[selectedConnector].isPlugged
                || connectors[selectedConnector].isTransactionStarted
              }
            >
              UnPlug Cable
            </Button>
            <DisconnectModal
              handleSubmit={handleSendMessageToWebSocket}
              messageType={messageTypes.CLOSE_CSMS_CONNECTION}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
        >
          <Typography
            variant="h4"
            fontWeight="900"
            marginRight="2%"
          >
            Charger Mode
          </Typography>
          <Tooltip title="Charger Mode">
            <img
              onClick={() => navigate(`/charger/${chargePointId}`)}
              className={classes.image}
              src={chargerImage}
              alt="EV charger"
            />
          </Tooltip>
          <Typography
            fontWeight="bold"
            marginRight="3%"
          >
            Energy supplied: {Math.round(connectors[selectedConnector].meterValue)} Wh
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h4">OCPP Message Monitor:</Typography>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={4}
          lg={4}
        >
          <Typography>Action</Typography>
        </Grid>
        <Grid
          item
          xs={8}
          lg={8}
        >
          <Typography>Payload</Typography>
        </Grid>
      </Grid>
      <Box className={classes.responsesContainer}>
        {messages.map((message, index) => (
          <Card key={index}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={4}
                lg={4}
              >
                <Typography component="data">{message.messageType}</Typography>
              </Grid>
              <Grid
                item
                xs={8}
                lg={8}
              >
                <Typography component="data">
                  {mapMessagePayload(message.payload)}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        ))}
      </Box>
    </>
  );
};
