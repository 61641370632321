import React from 'react';
import { createStoreProvider } from 'utils/context';

function useClearLogsState() {
  const [clearLogsDate, setClearLogsDate] = React.useState<Date>();

  return {
    clearLogsDate,
    setClearLogsDate,
  };
}

export const [useClearLogs, ClearLogsProvider] = createStoreProvider(useClearLogsState);
