export const MuiIconButton = {
  styleOverrides: {
    root: {
      color: '#FFFFFF',
      '&.Mui-disabled': {
        svg: {
          color: '#FFFFFF',
          opacity: 0.7,
        },
      },
    },
  },
};
