import { ChargePointStatus, chargePointStatuses } from '../../types';

export const statusColorMap = (status: ChargePointStatus): string => {
  switch (status) {
    case chargePointStatuses.CHARGING:
      return 'green';
    case chargePointStatuses.FAULTED:
    case chargePointStatuses.UNAVAILABLE:
      return 'red';
    case chargePointStatuses.PREPARING:
    case chargePointStatuses.RESERVED:
    case chargePointStatuses.FINISHING:
    case chargePointStatuses.OCCUPIED:
      return 'orange';
    default:
      return '';
  }
};
