import React from 'react';
import {
  MessageInterface,
  chargePointStatuses,
  Connectors,
  ChargePointStatus,
  ConnectorPayload,
} from 'types';
import { createStoreProvider } from 'utils/context';
import { useWs } from 'store/ws';
import { useMessage } from 'store/message';
import { useNavigate } from 'react-router-dom';
import { INITIAL_CONNECTORS, messageTypes } from '../../constants';

function useChargePointState() {
  const [selectedConnector, setSelectedConnector] = React.useState(1);
  const [isConnectionOnline, setIsConnectionOnline] = React.useState(true);
  const [connectors, setConnectors] = React.useState<Connectors>(INITIAL_CONNECTORS);
  const [messages, setMesssages] = React.useState<MessageInterface[]>([]);
  const [connectionTimeout, setConnectionTimeout] = React.useState<NodeJS.Timeout>();

  const { wsMessage, isConnected } = useWs();
  const { setMessage } = useMessage();
  const navigate = useNavigate();

  React.useEffect(() => {
    const handleResponseMessages = (event: MessageEvent<any>) => {
      const data = JSON.parse(event.data) as MessageInterface;
      const { messageType, payload } = data;

      if (
        messageType
        && payload.status
        && !messageType.includes('Connect')
        && !messageType.includes('Log')
        && !payload.status.includes('Fail')) {

        setMesssages((message) => [...message, data]);
      }

      switch (true) {
        case payload.status
          && (payload.status.includes('Reject')
            || payload.status.toLocaleLowerCase().includes('fail')):
          setMessage({
            type: 'error',
            text: payload.reason || '',
          });
          break;
        case messageType === messageTypes.PLUG
          && payload.status === 'CablePlugged':
          setConnectors((connector) => {
            return {
              ...connector,
              [payload.connectorId]: {
                ...connector[payload.connectorId],
                isPlugged: true,
              },
            };
          });
          break;
        case messageType === messageTypes.UNPLUG
          && payload.status === 'CableUnplugged':
          setConnectors((connector) => {
            return {
              ...connector,
              [payload.connectorId]: {
                ...connector[payload.connectorId],
                isPlugged: false,
                meterValue: payload.meterValue || 0,
              },
            };
          });
          break;
        case (messageType === messageTypes.START_TRANSACTION
          && payload.status === 'TransactionStarted')
          || payload.status === chargePointStatuses.CHARGING:
          setConnectors((connector) => {
            return {
              ...connector,
              [payload.connectorId]: {
                ...connector[payload.connectorId],
                chargePointStatus: chargePointStatuses.CHARGING,
                isCharging: true,
                isTransactionStarted: true,
              },
            };
          });
          break;
        case messageType === messageTypes.STOP_TRANSACTION
          && payload.status === 'TransactionStopped':
          setConnectors((connector) => {
            return {
              ...connector,
              [payload.connectorId]: {
                ...connector[payload.connectorId],
                isCharging: false,
                isTransactionStarted: false,
                isMeterValueStarted: false,
              },
            };
          });
          break;
        case messageType === messageTypes.START_METER_VALUES
          && payload.status === 'MeterValuesStarted':
          setConnectors((connector) => {
            return {
              ...connector,
              [payload.connectorId]: {
                ...connector[payload.connectorId],
                isMeterValueStarted: true,
              },
            };
          });
          break;
        case messageType === messageTypes.STOP_METER_VALUES
          && payload.status === 'MeterValuesStopped':
          setConnectors((connector) => {
            return {
              ...connector,
              [payload.connectorId]: {
                ...connector[payload.connectorId],
                isMeterValueStarted: false,
              },
            };
          });
          break;
        case messageType === messageTypes.GET_CP_INFORMATIONS:
          setConnectors((connector) => {
            return {
              ...connector,
              [payload.connectorId]: {
                ...connector[payload.connectorId],
                meterValue: payload.meterValue || 0,
              },
            };
          });
          break;
        case messageType === messageTypes.SELECT_CONNECTOR:
          setSelectedConnector(payload.connectorId);
          break;
        case messageType === messageTypes.GET_STATUS:
          setConnectors((connector) => {
            return {
              ...connector,
              [payload.connectorId]: {
                ...connector[payload.connectorId],
                chargePointStatus: payload.status as ChargePointStatus,
              },
            };
          });
          break;
        case messageType === messageTypes.GET_SETTINGS: {
          const payloadConnectors = payload.connectors as ConnectorPayload[];

          setConnectors((connector) => {
            return {
              ...connector,
              1: {
                ...connector[1],
                isTransactionStarted: payloadConnectors[1]?.hasOpenTransaction,
                isCharging: payloadConnectors[1]?.hasOpenTransaction,
                chargePointStatus: payloadConnectors[1]?.connectorStatus as ChargePointStatus || 'Available',
                isPlugged: payloadConnectors[1]?.isPlugged,
              },
              2: {
                ...connector[2],
                isTransactionStarted: payloadConnectors[2]?.hasOpenTransaction,
                isCharging: payloadConnectors[2]?.hasOpenTransaction,
                chargePointStatus: payloadConnectors[2]?.connectorStatus as ChargePointStatus || 'Available',
                isPlugged: payloadConnectors[2]?.isPlugged,
              },
            };
          });

          if (payload.selectedConnectorId) {
            setSelectedConnector(payload.selectedConnectorId);
          }

          break;
        }
        case messageType === messageTypes.GET_CONNECTION_STATUS:
          setIsConnectionOnline(payload.status === 'Online');

          // if (payload.status === 'Offline') {
          //   setMessage({
          //     type: 'info',
          //     text: 'Connection with CPMS lost!',
          //   });
          // }

          // if (payload.status === 'NotConnected') {
          //   navigate('/');
          // }

          break;
        case messageType === messageTypes.RESET
          && payload.status === 'Accepted':
          setConnectors(INITIAL_CONNECTORS);
          setMessage({
            type: 'info',
            text: 'Charger was reseted!',
          });
          break;
        case messageType === messageTypes.LOCK:
          setConnectors((connector) => {
            return {
              ...connector,
              [payload.connectorId]: {
                ...connector[payload.connectorId],
                isLocked: true,
              },
            };
          });
          break;
        case messageType === messageTypes.UNLOCK:
          setConnectors((connector) => {
            return {
              ...connector,
              [payload.connectorId]: {
                ...connector[payload.connectorId],
                isLocked: false,
              },
            };
          });
          break;
        default:
          break;
      }
    };

    if (wsMessage) {
      handleResponseMessages(wsMessage);
    }
  }, [setMessage, wsMessage]);

  React.useEffect(() => {
    if (isConnected && !isConnectionOnline && !connectionTimeout) {
      const timeout = setTimeout(() => {
        navigate('/');
      }, 30000);

      setConnectionTimeout(timeout);
    }

    if (isConnected && isConnectionOnline && connectionTimeout) {
      clearTimeout(connectionTimeout);
      setConnectionTimeout(undefined);
    }

  }, [isConnectionOnline, isConnected, navigate, connectionTimeout]);

  return {
    isConnectionOnline,
    selectedConnector,
    connectors,
    messages: [...messages].reverse(),
  };
}

export const [useChargePoint, ChargePointProvider] = createStoreProvider(useChargePointState);
