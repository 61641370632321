import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

import {
  Grid,
  Typography,
  Stack,
  LinearProgress,
  Button,
} from '@mui/material';

import BoltIcon from '@mui/icons-material/Bolt';
import EVChargerPort from 'assets/electric-charge.png';

import { TransactionModal } from 'components/modal/TransactionModal.tsx/TransactionModal';
import { Settings } from 'components/Settings/Settings';
import { Logs } from 'components/Logs/Logs';

import { useWs } from 'store/ws';
import { useChargePoint } from 'store/chargePoint';

import { statusColorMap } from 'utils/status-color';
import { MessageType, MessageTypeEnum } from 'types';
import { DisconnectModal } from 'components/modal/DisconnectModal.tsx/DisconnectModal';
import { OnlineOffline } from '../../components/OnlineOffline/OnlineOffline';
import { messageTypes } from '../../constants';
import useStyles from './ChargePointPage.styles';

export const ChargePointPage: React.FC = () => {
  const chargePointId = useParams().id || '';
  const [RFIDTag, setRFIDTag] = React.useState('');
  const [disable, setDisable] = React.useState(false);

  const { sendWSMessage } = useWs();
  const { selectedConnector, isConnectionOnline, connectors } = useChargePoint();
  const navigate = useNavigate();
  const classes = useStyles();

  const handleSelectConnectorMessage = (connectorId: number) => {
    sendWSMessage(MessageTypeEnum.SELECT_CONNECTOR, {
      idTag: RFIDTag,
      chargePointId,
      connectorId,
    });
  };

  const handleSendMessageToWebSocket = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const messageType = event.currentTarget.name as MessageType;

    if (messageType === MessageTypeEnum.PLUG || messageType === MessageTypeEnum.UNPLUG) {
      setDisable(true);

      setTimeout(() => {
        setDisable(false);
      }, 200);
    }

    sendWSMessage(messageType, {
      idTag: RFIDTag,
      chargePointId,
      connectorId: selectedConnector,
    });
  };

  return (
    <>
      <Typography
        variant="h2"
        textAlign="center"
        fontWeight="bold"
      >
        Charge Point: {chargePointId}
      </Typography>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        alignSelf="center"
        justifyContent="center"
      >
        <Grid item>
          <img
            src={EVChargerPort}
            className={classes.chargerPortIcon}
            onClick={() => {
              handleSelectConnectorMessage(1);
            }}
            style={{
              filter:
                `drop-shadow(0px 2px 4px ${statusColorMap(connectors[1].chargePointStatus)})`,
              opacity: selectedConnector === 2 ? 0.5 : 1,
            }}
          />
        </Grid>
        <Grid
          item
          width="64%"
        >
          <div className={classes.charger}>
            <div className={classes.chargerDisplay}>
              <span>Connector: {selectedConnector}</span>
              <span>Status: {_.upperFirst(connectors[selectedConnector].chargePointStatus)}</span>
              <span>Energy supplied: {Math.round(connectors[selectedConnector].meterValue)} Wh</span>
              <span>Connector is {connectors[selectedConnector].isLocked ? 'locked' : 'unlocked'}</span>
            </div>
            <BoltIcon
              className={classes.boltIcon}
              sx={{
                fontSize: '20vh',
                color: connectors[selectedConnector].isCharging ? '#38c538b0' : 'black',
              }}
            />
            {connectors[selectedConnector].isCharging && (
              <LinearProgress
                color="success"
                className={classes.linearProgress}
              />
            )}
            <Stack
              spacing={{ xl: 3, lg: 2, xs: 1 }}
              direction="row"
              className={classes.chargerButtonsContainer}
            >
              <Button
                name={connectors[selectedConnector].isPlugged ? messageTypes.UNPLUG : messageTypes.PLUG}
                variant="contained"
                size="large"
                onClick={handleSendMessageToWebSocket}
                disabled={(connectors[selectedConnector].isPlugged
                  && connectors[selectedConnector].isCharging) || disable}
              >
                {connectors[selectedConnector].isPlugged ? 'UnPlug Cable' : 'Plug Cable'}
              </Button>
              <TransactionModal
                name={connectors[selectedConnector].isCharging ? 'Stop Charging' : 'Start Charging'}
                messageType={connectors[selectedConnector].isCharging
                  ? messageTypes.STOP_CHARGING : messageTypes.START_CHARGING}
                RFIDTag={RFIDTag}
                setRFIDTag={setRFIDTag}
                handleSubmit={handleSendMessageToWebSocket}
                disabled={!connectors[selectedConnector].isCharging
                  ? connectors[selectedConnector].isCharging
                  || !connectors[selectedConnector].isPlugged
                  || connectors[selectedConnector].chargePointStatus === 'Finishing'
                  : !connectors[selectedConnector].isCharging}
              />
              {/* <TransactionModal
                name="Start Charging"
                messageType={messageTypes.START_CHARGING}
                RFIDTag={RFIDTag}
                setRFIDTag={setRFIDTag}
                handleSubmit={handleSendMessageToWebSocket}
                disabled={connectors[selectedConnector].isCharging || !connectors[selectedConnector].isPlugged}
              />
              <TransactionModal
                name="Stop Charging"
                messageType={messageTypes.STOP_CHARGING}
                RFIDTag={RFIDTag}
                setRFIDTag={setRFIDTag}
                handleSubmit={handleSendMessageToWebSocket}
                disabled={!connectors[selectedConnector].isCharging}
              /> */}
            </Stack>

            <div className={classes.chargerFooter}>
              <OnlineOffline
                isOnline={isConnectionOnline}
                debug={false}
              />
              <DisconnectModal
                handleSubmit={handleSendMessageToWebSocket}
                messageType={messageTypes.CLOSE_CSMS_CONNECTION}
              />
              <Button
                name="Debug Mode"
                variant="contained"
                size="large"
                onClick={() => navigate(`/debug-charger/${chargePointId}`)}
              >
                Debug Mode
              </Button>
              <Settings
                chargePointId={chargePointId}
                isDisabled={connectors[selectedConnector].isCharging}
              />
              <Logs chargePointId={chargePointId} />
            </div>
          </div>
        </Grid>
        <Grid item>
          <img
            src={EVChargerPort}
            className={classes.chargerPortIcon}
            onClick={() => handleSelectConnectorMessage(2)}
            style={{
              transform: 'scaleX(-1)',
              filter: `drop-shadow(0px 2px 4px ${statusColorMap(connectors[2].chargePointStatus)})`,
              opacity: selectedConnector === 1 ? 0.5 : 1,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
